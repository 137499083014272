import { Form } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NewScheduleEvent } from './types';

import { api } from '../../../services/api';
import { services } from '../../../services/services';
import { formatDataToSelect } from '../../../helpers/nUtils';
import { StyledSelectOptions } from '../../../components/Common/StyledSelect/types';
import { useAlert } from '../../../context/AlertContext';

export function useModalNewEvent() {
  const [form] = Form.useForm<NewScheduleEvent>();
  const [qualificationsIds, setQualificationsIds] = useState<Array<StyledSelectOptions>>([]);
  const [partNumbers, setPartNumbers] = useState<Array<StyledSelectOptions>>([]);
  const [qualificationIdSelected, setQualificationIdSelected] = useState('');
  const [partNumberSelected, setPartNumberSelected] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { openAlert } = useAlert();
  const { t } = useTranslation();
  const statusCard: Array<StyledSelectOptions> = [
    {
      value: 'QG',
      label: 'QG',
    },
    {
      value: 'QF',
      label: 'QF',
    },
    {
      value: 'QC',
      label: 'QC',
    },
    {
      value: 'QD',
      label: 'QD',
    },
  ];

  const handleFetchQualificationIds = async () => {
    try {
      setIsLoading(true);
      const { data, status } = await api.get(
        `${services.qualification}/schedule/event/fields?fieldToReturn=qualificationName`
      );
      status === 200 && setQualificationsIds(formatDataToSelect(data, false));
      status === 204 && setQualificationsIds([]);
    } catch (error) {
      openAlert('error', t('toast.errorOnList'));
      setQualificationsIds([]);
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFetchParNumbers = async () => {
    try {
      setIsLoading(true);
      const { data, status } = await api.get(
        `${services.qualification}/schedule/event/fields?fieldToReturn=partNumber&id=${qualificationIdSelected}`
      );
      status === 200 && setPartNumbers(formatDataToSelect(data, false));
      status === 204 && setPartNumbers([]);
    } catch (error) {
      openAlert('error', t('toast.errorOnList'));
      setPartNumbers([]);
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdateQualificationId = (qualificationId: string) => {
    setPartNumberSelected('');
    setPartNumbers([]);
    form.resetFields([
      'createdBy',
      'date',
      'odm',
      'partNumber',
      'projectName',
      'status',
      'supplier',
    ]);
    setQualificationIdSelected(qualificationId);
  };

  const handleLoadDataModal = (data: NewScheduleEvent) => {
    form.setFieldsValue({
      projectName: data.projectName,
      supplier: data.supplier,
      odm: data.odm,
      createdBy: data.createdBy,
    });
  };

  const handleFetchOtherData = async () => {
    try {
      setIsLoading(true);
      const { data, status } = await api.get(
        `${services.qualification}/schedule/event?qualificationName=${qualificationIdSelected}&partNumber=${partNumberSelected}`
      );
      status === 200 && handleLoadDataModal(data);
    } catch (error) {
      setPartNumbers([]);
      openAlert('error', t('toast.errorOnList'));
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSaveNewEvent = async (values: NewScheduleEvent, closeModal: () => void) => {
    try {
      setIsLoading(true);
      const { status } = await api.post(`${services.qualification}/schedule/event`, {
        idQualification: values.idQualification,
        partNumber: values.partNumber,
        projectName: values.projectName,
        supplier: values.supplier,
        odm: values.odm,
        createdBy: values.createdBy,
        date: values.date.format('DD/MM/YYYY'),
        status: values.status,
      });

      if (status === 201) {
        openAlert('info', t('toast.successOnSave'));
        closeModal();
      }
    } catch (error) {
      openAlert('error', t('toast.errorOnSave'));
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    form,
    isLoading,
    statusCard,
    qualificationsIds,
    qualificationIdSelected,
    partNumbers,
    partNumberSelected,
    setPartNumberSelected,
    setPartNumbers,
    setIsLoading,
    handleFetchQualificationIds,
    handleFetchParNumbers,
    handleFetchOtherData,
    handleUpdateQualificationId,
    handleSaveNewEvent,
  };
}
