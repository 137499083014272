import { ReactNode, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { CloseOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { api } from '../../services/api';
import { services } from '../../services/services';
import { StyledSelectOptions } from '../../components/Common/StyledSelect/types';
import { formatDataToSelect } from '../../helpers/nUtils';
import StyledFilterTag from '../../components/Common/StyledFilterTag';
import theme from '../../styles/theme';
import StyledClearTag from '../../components/Common/StyledFilterTag/StyledClearTag';
import { useAlert } from '../../context/AlertContext';
import { getURI } from '../../helpers/utils';
import { Events } from './types';
import { CardEventDetails } from './CardEventDetails';
import { DayScheduleProps, PartNumbersEvent } from './ScheduleDay/types';
import { ScheduleDay } from './ScheduleDay';

const initialParams = {
  partNumber: '',
  projectName: '',
  supplier: '',
  odm: '',
};

export function useSchedule() {
  const [visibleYearSchedule, setVisibleYearSchedule] = useState(false);
  const [visibleModalSchedule, setVisibleModalSchedule] = useState(false);
  const [visibleFirtPlanDate, setVisibleFirtPlanDate] = useState(true);
  const [visibleDelayed, setVisibleDelayed] = useState(true);
  const [partNumbers, setPartNumbers] = useState<Array<StyledSelectOptions>>([]);
  const [projects, setProjects] = useState<Array<StyledSelectOptions>>([]);
  const [suppliers, setSupliers] = useState<Array<StyledSelectOptions>>([]);
  const [odms, setOdms] = useState<Array<StyledSelectOptions>>([]);
  const [params, setParams] = useState(initialParams);
  const [date, setDate] = useState<Dayjs>(dayjs());
  const [isLoading, setIsLoading] = useState(false);
  const [events, setEvents] = useState<Array<Events>>([]);
  const { openAlert } = useAlert();
  const { t } = useTranslation();

  const handleVisibilitySchedule = () => {
    setVisibleYearSchedule(!visibleYearSchedule);
  };

  const handleFetchPartNumbersFilters = async () => {
    try {
      const { data, status } = await api.get(
        `${services.qualification}/schedule/header/filter?filterToReturn=partNumber`
      );
      status === 200 && setPartNumbers(formatDataToSelect(data, false) || []);
    } catch (error) {
      setProjects([]);
      console.log(error);
    }
  };

  const handleProjectsFilters = async () => {
    try {
      const { data, status } = await api.get(
        `${services.qualification}/schedule/header/filter?filterToReturn=projectName`
      );
      status === 200 && setProjects(formatDataToSelect(data, false) || []);
    } catch (error) {
      console.log(error);
      setProjects([]);
    }
  };

  const handleFetchSuppliersFilters = async () => {
    try {
      const { data, status } = await api.get(
        `${services.qualification}/schedule/header/filter?filterToReturn=supplier`
      );
      status === 200 && setSupliers(formatDataToSelect(data, false) || []);
    } catch (error) {
      setProjects([]);
      console.log(error);
    }
  };

  const handleFetchOdmFilters = async () => {
    try {
      const { data, status } = await api.get(
        `${services.qualification}/schedule/header/filter?filterToReturn=odm`
      );
      status === 200 && setOdms(formatDataToSelect(data, false) || []);
    } catch (error) {
      console.log(error);
      setOdms([]);
    }
  };

  const handleGetAllFilters = () => {
    Promise.all([
      handleFetchPartNumbersFilters(),
      handleProjectsFilters(),
      handleFetchSuppliersFilters(),
      handleFetchOdmFilters(),
    ]);
  };

  const handleOpenCloseFirstPlanDate = () => {
    setVisibleFirtPlanDate((prevState) => !prevState);
  };

  const handleOpenCloseDelayed = () => {
    setVisibleDelayed((prevState) => !prevState);
  };

  const handleGetMonthName = (filterType: 'YEAR' | 'MONTH'): string => {
    return filterType === 'MONTH' ? date.format('MMMM') : date.format('YYYY');
  };

  const handleUpdateDate = (selectedDate: dayjs.Dayjs, filterType: 'DAY' | 'MONTH' | 'YEAR') => {
    try {
      switch (filterType) {
        case 'DAY':
          setDate((currentDate) => {
            return currentDate.set('date', selectedDate.date());
          });
          break;
        case 'MONTH':
          setDate((currentDate) => {
            return currentDate.set('month', selectedDate.month());
          });

          break;
        case 'YEAR':
          setDate((currentDate) => {
            return currentDate.set('year', selectedDate.year());
          });
          break;
        default:
      }
    } catch (error) {
      console.log(error);
    }
  };

  const clearAllFilters = () => {
    setParams(initialParams);
  };

  const handleUpdateFilters = (field: string, value: any) => {
    setParams((oldParams) => {
      return {
        ...oldParams,
        [field]: value,
      };
    });
  };

  const handleRenderFiltersTags = () => {
    return (
      <>
        {params.partNumber && (
          <StyledFilterTag
            label={t('pages.scheduleQualification.labels.partNumber')}
            closeicon={<CloseOutlined color={theme.colors.prim} />}
            title={params.partNumber}
            closeble
            onClose={() => {
              handleUpdateFilters('partNumber', '');
            }}
          />
        )}

        {params.projectName && (
          <StyledFilterTag
            label={t('pages.scheduleQualification.labels.projectName')}
            closeicon={<CloseOutlined color={theme.colors.prim} />}
            title={params.projectName}
            closeble
            onClose={() => {
              handleUpdateFilters('projectName', '');
            }}
          />
        )}
        {params.supplier && (
          <StyledFilterTag
            label={t('pages.scheduleQualification.labels.supplier')}
            closeicon={<CloseOutlined color={theme.colors.prim} />}
            title={params.supplier}
            closeble
            onClose={() => {
              handleUpdateFilters('supplier', '');
            }}
          />
        )}
        {params.odm && (
          <StyledFilterTag
            label={t('pages.scheduleQualification.labels.ODM')}
            closeicon={<CloseOutlined color={theme.colors.prim} />}
            title={params.odm}
            closeble
            onClose={() => {
              handleUpdateFilters('odm', '');
            }}
          />
        )}
      </>
    );
  };

  const handleRenderClearAllFilters = () => {
    if (params.supplier || params.partNumber || params.projectName || params.odm) {
      return <StyledClearTag onClick={clearAllFilters} />;
    }
  };

  const handleFetchScheduleEvents = async () => {
    try {
      setIsLoading(true);
      const { data, status } = await api.get<Record<string, Array<Events>>>(
        getURI(`${services.qualification}/schedule`, params)
      );
      if (status === 200 && data) {
        const convertedEvents = Object.entries(data).flatMap(([eventDate, items]) =>
          items.map((item) => ({ ...item, eventDate }))
        );
        setEvents(convertedEvents);
      }
      status === 204 && setEvents([]);
    } catch (error) {
      openAlert('error', t('toast.errorOnList'));
      setEvents([]);
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleGetQuantityEventsToday = (): number => {
    try {
      return events.filter((event) => dayjs(event.eventDate, 'DD-MM-YYYY').isSame(date, 'day'))
        .length;
    } catch (error) {
      return 0;
      console.log(error);
    }
  };

  const handleRenderCardsFirsPlanDate = () => {
    const filterdEvens = events
      .filter((event) => dayjs(event.eventDate, 'DD-MM-YYYY').isSame(date, 'day'))
      .filter(
        (event) =>
          dayjs(event.firstPlanDate, 'DD-MM-YYYY').isSame(date, 'day') ||
          dayjs(event.firstPlanDate, 'DD-MM-YYYY').isBefore(dayjs(), 'day')
      );
    return filterdEvens.map((event) => {
      return (
        <CardEventDetails
          key={event.partNumber + event.projectName}
          partNumber={event.partNumber}
          projectName={event.projectName}
          supplierName={event.supplier}
          odmName={event.odm}
          qStatus={event.qstatus}
          delayed={false}
        />
      );
    });
  };

  const handleRenderCardsDelayed = () => {
    const filterdEvens = events
      .filter((event) => dayjs(event.eventDate, 'DD-MM-YYYY').isSame(date, 'day'))
      .filter((event) => dayjs(event.firstPlanDate, 'DD-MM-YYYY').isAfter(dayjs(), 'day'));
    return filterdEvens.map((event) => {
      return (
        <CardEventDetails
          key={event.partNumber + event.projectName}
          partNumber={event.partNumber}
          projectName={event.projectName}
          supplierName={event.supplier}
          odmName={event.odm}
          qStatus={event.qstatus}
          delayed
        />
      );
    });
  };

  const handleGetEventsPartNumberByDate = (dateEvent: dayjs.Dayjs): Array<PartNumbersEvent> => {
    try {
      const filteredEvens = events.filter((event) =>
        dayjs(event.eventDate, 'DD-MM-YYYY').isSame(dayjs(dateEvent, 'DD-MM-YYYY'), 'day')
      );

      return filteredEvens.map((event) => {
        return {
          partNumber: event.partNumber,
          isDelayed:
            dayjs(event.firstPlanDate, 'DD-MM-YYYY').isSame(dateEvent, 'day') ||
            dayjs(event.firstPlanDate, 'DD-MM-YYYY').isBefore(dateEvent, 'day'),
        };
      });
    } catch (error) {
      console.log(error);
      return [];
    }
  };

  const handleRenderScheduleDay = (
    currentDay: dayjs.Dayjs,
    type: DayScheduleProps['type']
  ): ReactNode => {
    try {
      return (
        <ScheduleDay
          partNumbers={handleGetEventsPartNumberByDate(currentDay)}
          currentDay={currentDay}
          value={date}
          type={type}
        />
      );
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  const handleCloseModal = () => setVisibleModalSchedule(false);

  return {
    visibleYearSchedule,
    visibleModalSchedule,
    date,
    partNumbers,
    projects,
    odms,
    suppliers,
    params,
    events,
    isLoading,
    visibleDelayed,
    visibleFirtPlanDate,
    handleRenderScheduleDay,
    handleUpdateFilters,
    handleGetAllFilters,
    setVisibleModalSchedule,
    handleCloseModal,
    handleVisibilitySchedule,
    handleGetMonthName,
    handleRenderFiltersTags,
    handleUpdateDate,
    handleRenderClearAllFilters,
    handleFetchScheduleEvents,
    handleGetQuantityEventsToday,
    handleRenderCardsFirsPlanDate,
    handleRenderCardsDelayed,
    handleGetEventsPartNumberByDate,
    setVisibleFirtPlanDate,
    setVisibleDelayed,
    handleOpenCloseFirstPlanDate,
    handleOpenCloseDelayed,
  };
}
