import {
  ContainerOutlined,
  CopyOutlined,
  MailOutlined,
  MobileOutlined,
  PhoneOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Col, Divider, Empty, Row, Tabs } from 'antd';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAlert } from '../../../../context/AlertContext';
import { useFormaters } from '../../../../helpers/useFortmaters';
import * as S from './styles';
import { IODMDetailsComponent } from './types';
import { useODMDetails } from './useODMDetails';

export function ODMDetails({ data }: IODMDetailsComponent) {
  const { openAlert } = useAlert();
  const { t } = useTranslation();
  const { formatPhoneNumber, formatMobilePhoneNumber, formatZipCode } = useFormaters();
  const { formatODMTabs, odmTabs, selectedTab, onChangeTab, selectedODM } = useODMDetails();

  useEffect(() => {
    data && formatODMTabs(data);
  }, [data]);

  return (
    <S.Container>
      <S.Header>
        <S.Padding>
          <ContainerOutlined />
          {t('pages.viewQualification.odmDetails.title')}
        </S.Padding>
      </S.Header>
      <S.Content>
        {!data ? (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        ) : (
          <>
            <Tabs
              tabBarGutter={16}
              items={odmTabs}
              animated
              onChange={(value) => onChangeTab(value, data || [])}
              activeKey={selectedTab}
            />
            <S.Card>
              {t('pages.viewQualification.odmDetails.sampleQuantity')}{' '}
              <span className="value">{selectedODM?.sampleQuantity}</span>
            </S.Card>
            <Divider />
            <S.Title>{t('pages.viewQualification.odmDetails.odmContactInformation')}</S.Title>
            <Col span={24}>
              <Row>
                <Col span={6}>
                  <S.Label>
                    <UserOutlined />
                    {t('pages.viewQualification.odmDetails.companyContacPerson')}
                  </S.Label>
                  <S.Value>{selectedODM?.companyContactPerson}</S.Value>
                </Col>

                <Col span={6}>
                  <S.Label>
                    <MailOutlined />
                    {t('pages.viewQualification.odmDetails.email')}
                  </S.Label>
                  <S.Value>{selectedODM?.email}</S.Value>
                </Col>

                <Col span={6}>
                  <S.Label>
                    <MobileOutlined />
                    {t('pages.viewQualification.odmDetails.mobileNumber')}
                  </S.Label>
                  <S.Value>{formatMobilePhoneNumber(selectedODM?.mobileNumber)}</S.Value>
                </Col>
                <Col span={6}>
                  <S.Label>
                    <PhoneOutlined />
                    {t('pages.viewQualification.odmDetails.phoneNumber')}
                  </S.Label>
                  <S.Value>{formatPhoneNumber(selectedODM?.phoneNumber)}</S.Value>
                </Col>
              </Row>
            </Col>
            <Divider />
            <S.Title>{t('pages.viewQualification.odmDetails.sampleShippingAddress')}</S.Title>
            <Col span={24}>
              <Row>
                <Col span={6}>
                  <S.Label>{t('pages.viewQualification.odmDetails.zipCode')}</S.Label>
                  <S.Value>
                    {formatZipCode(selectedODM?.zipCode)}
                    <CopyOutlined
                      onClick={() => {
                        selectedODM?.zipCode &&
                          navigator.clipboard.writeText(selectedODM.zipCode).then(() => {
                            openAlert('info', t('common.copiedToClipboard'));
                          });
                      }}
                    />
                  </S.Value>
                </Col>

                <Col span={6}>
                  <S.Label>{t('pages.viewQualification.odmDetails.country')}</S.Label>
                  <S.Value>{selectedODM?.country}</S.Value>
                </Col>

                <Col span={12}>
                  <S.Label>{t('pages.viewQualification.odmDetails.address')}</S.Label>
                  <S.Value>
                    {selectedODM?.address}
                    <CopyOutlined
                      onClick={() => {
                        selectedODM?.address &&
                          navigator.clipboard.writeText(selectedODM.address).then(() => {
                            openAlert('info', t('common.copiedToClipboard'));
                          });
                      }}
                    />
                  </S.Value>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <S.Label marginTop>
                {t('pages.viewQualification.odmDetails.additionalInformation')}
              </S.Label>
              <S.Value>{selectedODM?.additionalInfo}</S.Value>
            </Col>{' '}
          </>
        )}
      </S.Content>
    </S.Container>
  );
}
