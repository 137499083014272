import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledSelectOptions } from '../../../../components/Common/StyledSelect/types';
import { useAlert } from '../../../../context/AlertContext';
import { formatDataToSelect } from '../../../../helpers/nUtils';
import { api } from '../../../../services/api';
import { dataType } from '../types';
import { DataGraphicProps } from './types';

export const useStartvsEnd = (
  dateProps: dayjs.Dayjs,
  skusProps: dataType[],
  level: string,
  isFirstRendering: boolean,
  cm?: string
) => {
  const { openAlert } = useAlert();
  const { t } = useTranslation();
  const [data, setData] = useState<DataGraphicProps[]>();
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const [categories, setCategories] = useState<Array<StyledSelectOptions>>([]);
  const [params, setParams] = useState({
    category: '',
  });
  const [isLoadingDataGraphic, setIsLoadingDataGraphic] = useState<boolean>(false);

  const colors = {
    endStock: '#DBDBDB',
  };

  const toogleView = () => {
    setIsOpen(!isOpen);
  };

  const updateDateByExternalPicker = (date: dayjs.Dayjs) => {
    return {
      date: [
        dayjs(date).subtract(1, 'month').format('MMMM/YYYY').toUpperCase(),
        dayjs(date).format('MMMM/YYYY').toUpperCase(),
        dayjs(date).add(1, 'month').format('MMMM/YYYY').toUpperCase(),
      ].join(','),
    };
  };

  const fetchCategories = async () => {
    try {
      const newDate = updateDateByExternalPicker(dateProps);
      const { data: categoriesData, status: categoriesStatus } = await api.get<string[]>(
        '/dashboard/finance/skucomponents/category',
        {
          params: {
            ...newDate,
            skus: skusProps.map((item) => item.sku).join(','),
            level,
            ...(cm && { cm }),
          },
        }
      );

      if (categoriesStatus === 204) {
        setCategories([]);
        setParams({ ...params, category: '' });
        return;
      }

      if (categoriesStatus === 200) {
        if (categoriesData.length > 0) {
          setParams({ ...params, category: categoriesData[0] });
          setCategories(formatDataToSelect(categoriesData, false));
          return;
        }

        setCategories([]);
        setParams({ ...params, category: '' });
      }
    } catch (error) {
      openAlert('error', t('toast.errorOnList'));
    }
  };

  const formatValue = (value: string | number) => {
    const formattedValue = value.toString().replace(',', '');
    return Number(formattedValue);
  };

  const verifySkus = () => {
    //remove sku da compal para evitar erro
    //não possuimos arquivo de estoque para compal
    return skusProps.filter((sku) => sku.cm === 'Foxconn');
  };

  const formatDataGraph = (dataProps: DataGraphicProps[]) => {
    return dataProps.map((item) => {
      return {
        ...item,
        graphic: item.graphic.map((graphic) => {
          return {
            start: formatValue(graphic.start),
            end: formatValue(graphic.end),
            name: graphic.name,
          };
        }),
      };
    });
  };

  const fetchData = async () => {
    try {
      setIsLoadingDataGraphic(true);
      setData([]);
      const newDate = updateDateByExternalPicker(dateProps);

      const { data: graphicData, status } = await api.get<DataGraphicProps[]>(
        '/dashboard/finance/startend',
        {
          params: {
            category: params.category,
            //Foxconn está fixo porque ainda não temos arquivo para estoque Compal
            //Quando existir, deve ser alterado para receber o CM do header principal
            cm: 'Foxconn',
            ...newDate,
            level,
            skus: verifySkus()
              .map((item) => item.sku)
              .join(','),
          },
        }
      );
      if (status === 204) {
        return setData([]);
      }

      setData(formatDataGraph(graphicData));
    } catch (error) {
      setData([]);
      openAlert('error', t('toast.errorOnList'));
    } finally {
      setIsLoadingDataGraphic(false);
    }
  };

  useEffect(() => {
    !isFirstRendering && verifySkus().length > 0 && fetchCategories();
  }, [dateProps, level, skusProps]);

  useEffect(() => {
    setParams((prev) => {
      return { ...prev, category: '' };
    });
  }, [level]);

  useEffect(() => {
    !isFirstRendering && params.category && verifySkus().length > 0 && fetchData();
  }, [dateProps, level, skusProps, params]);

  useEffect(() => {
    !isFirstRendering && cm?.toLowerCase() === 'compal' && setIsOpen(false);
  }, [cm]);

  return {
    data,
    fetchData,
    isOpen,
    toogleView,
    colors,
    categories,
    setParams,
    params,
    isLoadingDataGraphic,
  };
};
