import {
  DeleteOutlined,
  DownloadOutlined,
  DownOutlined,
  PlusOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import { Dropdown, MenuProps, Popconfirm, Row } from 'antd';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import find from '../../assets/find.svg';
import { ActionButton } from '../../components/ActionButton';
import { CsvExport } from '../../components/Common/CsvExport';
import StyledButton from '../../components/Common/StyledButton';
import { StyledHeader } from '../../components/Common/StyledHeaderPage';
import StyledModal from '../../components/Common/StyledModal';
import { StyledPagination } from '../../components/Common/StyledPagination';
import StyledSelect from '../../components/Common/StyledSelect';
import CsvUploadReader from '../../components/CsvUploadReader';
import { Loading } from '../../components/Loading';
import ContentNewTax from '../../components/Modal/ContentNewTax';
import SimpleTable from '../../components/Tables/SimpleTable';
import { getURI, states } from '../../helpers/utils';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import * as S from './styles';
import { useSettingsTaxe } from './useSettingTaxes';

export function SettingsTaxes() {
  const { t } = useTranslation();
  const {
    columns,
    taxesData,
    showModal,
    showUploadFileModal,
    isLoading,
    editTax,
    headerCsv,
    pageControl,
    TYPES,
    params,
    handleUpdateParams,
    changePage,
    transformDataToCsv,
    setShowModal,
    handleCloseModalUploadFiles,
    saveNewTaxe,
    handleFetchTaxesData,
    handleOpenModalNewTaxe,
    handleRenderFooterModalUpload,
    handleFormatJsonToUpload,
    setShowUploadFileModal,
    handleClearTaxesToUpload,
    handleDeleteAllTaxes,
    handleRenderClearAllFilters,
    handleRenderFiltersTags,
    form,
  } = useSettingsTaxe();

  useEffect(() => {
    handleFetchTaxesData();
  }, [params]);

  useDocumentTitle(t('pages.taxes.title'));

  const actions: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <ActionButton icon={<PlusOutlined />} onClick={handleOpenModalNewTaxe}>
          {t('pages.taxes.addTax')}
        </ActionButton>
      ),
    },
    {
      key: '2',
      label: (
        <ActionButton icon={<UploadOutlined />}>
          <CsvExport
            headers={headerCsv}
            transformData={transformDataToCsv}
            filename="Taxes"
            limit={pageControl.totalElements}
            url={getURI('taxRateSettings', params)}
          />
        </ActionButton>
      ),
    },
    {
      key: '3',
      label: (
        <ActionButton
          onClick={() => {
            setShowUploadFileModal(true);
          }}
          icon={<DownloadOutlined />}
        >
          {t('common.fields.uploadFile')}
        </ActionButton>
      ),
    },
    {
      key: '4',
      label: (
        <Popconfirm
          title={t('common.deleteMessageAll')}
          onConfirm={handleDeleteAllTaxes}
          onClick={() => {
            console.log('empty');
          }}
        >
          <ActionButton disabled={!taxesData.length} icon={<DeleteOutlined />}>
            {t('common.deleteAll')}
          </ActionButton>
        </Popconfirm>
      ),
    },
  ];

  return (
    <S.Container data-cy="settingTaxes-page">
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <StyledHeader
            title={t('pages.taxes.title')}
            items={pageControl.totalElements}
            actions={
              <Dropdown key={0} menu={{ items: actions }} placement="bottomRight">
                <StyledButton variant="primary">
                  {t('common.action')}
                  <DownOutlined />
                </StyledButton>
              </Dropdown>
            }
          />

          <S.FiltersContainer>
            <S.Filters>
              <StyledSelect
                type="COMPONENT_TYPE"
                styles={{ minWidth: '11.25rem' }}
                suffixIcon={<img src={find} alt="lupa" />}
                placeholder={t('pages.taxes.placeholders.component')}
                onChange={(value: any) => {
                  handleUpdateParams('componentType', value);
                }}
                value={params.componentType || null}
                label={t('pages.taxes.labels.category')}
                showSearch
                data-cy="settingTaxes-linkFilter"
              />
              <StyledSelect
                options={TYPES}
                styles={{ minWidth: '8.5rem' }}
                placeholder={t('pages.taxes.placeholders.select')}
                onChange={(value: any) => {
                  handleUpdateParams('type', value);
                }}
                value={params.type || null}
                label={t('pages.taxes.labels.type')}
              />
              <StyledSelect
                placeholder={t('pages.taxes.placeholders.from')}
                onChange={(value: any) => {
                  handleUpdateParams('from', value);
                }}
                label={t('pages.taxes.placeholders.from')}
                options={states('')}
                value={params.from || null}
                showSearch
                data-cy="settingTaxes-fromFilter"
              />
              <StyledSelect
                placeholder={t('pages.taxes.placeholders.to')}
                onChange={(value: any) => {
                  handleUpdateParams('to', value);
                }}
                label={t('pages.taxes.placeholders.to')}
                options={states('')}
                value={params.to || null}
                showSearch
                data-cy="settingTaxes-toFilter"
              />
            </S.Filters>
          </S.FiltersContainer>
          <S.FiltersTagContainer>
            {handleRenderFiltersTags()}
            {handleRenderClearAllFilters()}
          </S.FiltersTagContainer>
          <S.TableContainer>
            <SimpleTable
              striped
              pagination={false}
              columns={columns}
              dataSource={taxesData}
              rowKey=""
              scroll={{ x: 'max-content', y: 'calc(100vh - 24rem)' }}
            />
          </S.TableContainer>

          {showModal && (
            <StyledModal
              key="modal-tax"
              title={t('components.modals.addNewTax.title')}
              open={showModal}
              onClose={() => {
                setShowModal(false);
                form.resetFields();
              }}
              width="50rem"
              centered
              footer={
                <Row justify="space-between">
                  <StyledButton
                    variant="secondary"
                    onClick={() => {
                      setShowModal(false);
                      form.resetFields();
                    }}
                  >
                    {t('common.cancel')}
                  </StyledButton>
                  <StyledButton
                    variant="primary"
                    onClick={() => {
                      form.validateFields().then(() => {
                        saveNewTaxe(form.getFieldsValue(true));
                      });
                    }}
                  >
                    {editTax ? t('common.save') : t('common.add')}
                  </StyledButton>
                </Row>
              }
              body={<ContentNewTax taxe={editTax} saveNewTax={saveNewTaxe} form={form} />}
            />
          )}

          {showUploadFileModal && (
            <StyledModal
              title={t('pages.taxes.title')}
              open={showUploadFileModal}
              onClose={() => {
                handleCloseModalUploadFiles();
              }}
              width="46rem"
              footer={handleRenderFooterModalUpload()}
              body={
                <CsvUploadReader
                  onRemoveFile={() => handleClearTaxesToUpload()}
                  getCsvData={async (csvData: any) => {
                    handleFormatJsonToUpload(csvData);
                  }}
                />
              }
            />
          )}
        </>
      )}
      {pageControl.totalPages > 1 && (
        <StyledPagination
          size={params.limit}
          totalElements={pageControl.totalElements}
          pageNumber={params.page + 1}
          onChange={changePage}
        />
      )}
    </S.Container>
  );
}
