import { ThemeConfig } from 'antd';
import theme from './theme';

export const antTheme: ThemeConfig = {
  token: {
    fontFamily: 'AcerFoco',
    colorPrimary: theme.colorsDesignSystem.primary,
    colorIcon: theme.colorsDesignSystem.primary,
    colorTextTertiary: theme.colorsDesignSystem.primary,
  },
  components: {
    Steps: {
      margin: 16,
      fontSize: 14,
    },
    DatePicker: {
      colorIcon: theme.colorsDesignSystem.primary,
    },
    Select: {
      controlItemBgActive: theme.colorsDesignSystem.primary,
      colorTextPlaceholder: theme.colors.inputPlaceHolder,
    },
    Input: {
      colorBorder: theme.colors.gray_border,
      borderRadius: 6,
    },
    Table: {
      headerBg: theme.colorsDesignSystem.table.headerBg,
      borderColor: theme.colorsDesignSystem.table.border,
      fontSize: 14,
      colorTextHeading: theme.colorsDesignSystem.table.text,
      fontWeightStrong: 400,
      colorText: theme.colorsDesignSystem.table.text,
      headerSplitColor: theme.colorsDesignSystem.table.headerSplit,
      borderRadius: 6,
      rowHoverBg: theme.colorsDesignSystem.tableHover,
    },
    Pagination: {
      itemActiveBg: 'transparent',
      itemBg: 'transparent',
    },
    Button: {
      borderRadius: 8,
      primaryColor: theme.colorsDesignSystem.white,
      colorLink: theme.colorsDesignSystem.primary,
      colorPrimaryBgHover: theme.colorsDesignSystem.primary,
      colorLinkHover: theme.colorsDesignSystem.primary,
    },
    Menu: {
      colorText: theme.colorsDesignSystem.primary,
    },
    Layout: {
      bodyBg: theme.colors.newBackground,
    },
    Tabs: {
      itemSelectedColor: theme.colorsDesignSystem.table.text,
      inkBarColor: theme.colorsDesignSystem.text,
      itemColor: theme.colorsDesignSystem.primary,
    },
    Notification: {
      borderRadius: 8,
      colorSuccessBg: theme.colorsDesignSystem.lightPrimary,
      colorInfoBg: theme.colorsDesignSystem.secondLightBlue,
      colorWarningBg: theme.colorsDesignSystem.lightYellow,
      colorErrorBg: theme.colorsDesignSystem.lightRed,
    },
    Alert: {
      borderRadiusLG: 6,
      colorSuccess: theme.colorsDesignSystem.primary,
      colorSuccessBorder: theme.colorsDesignSystem.primary,
      colorInfo: theme.colors.blue_secondary,
      colorInfoBorder: theme.colorsDesignSystem.blueBorder,
      colorWarning: theme.colorsDesignSystem.yellow,
      colorWarningBorder: theme.colorsDesignSystem.yellow,
      colorError: theme.colorsDesignSystem.red,
      colorErrorBorder: theme.colorsDesignSystem.red,
    },
  },
};
