import { t } from 'i18next';
import { Calendar, Flex, Row } from 'antd';
import { DownOutlined, SearchOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import * as S from './styles';
import StyledSelect from '../../components/Common/StyledSelect';
import StyledButton from '../../components/Common/StyledButton';
import theme from '../../styles/theme';
import { Months } from './Months';
import { useSchedule } from './useSchedule';
import StyledModal from '../../components/Common/StyledModal';
import ModalNewEvent from './ModalNewEvent';
import { getFormattedDateWithMonthAndDay } from '../../helpers/nUtils';
import { Loading } from '../../components/Loading';

export function Schedule() {
  const {
    visibleYearSchedule,
    visibleModalSchedule,
    date,
    partNumbers,
    projects,
    odms,
    suppliers,
    params,
    events,
    visibleDelayed,
    visibleFirtPlanDate,
    isLoading,
    handleOpenCloseDelayed,
    handleOpenCloseFirstPlanDate,
    handleVisibilitySchedule,
    setVisibleModalSchedule,
    handleCloseModal,
    handleGetMonthName,
    handleGetAllFilters,
    handleUpdateDate,
    handleRenderFiltersTags,
    handleRenderClearAllFilters,
    handleUpdateFilters,
    handleFetchScheduleEvents,
    handleGetQuantityEventsToday,
    handleRenderCardsFirsPlanDate,
    handleRenderCardsDelayed,
    handleGetEventsPartNumberByDate,
    handleRenderScheduleDay,
  } = useSchedule();

  useEffect(() => {
    handleGetAllFilters();
  }, []);

  useEffect(() => {
    handleFetchScheduleEvents();
  }, [params.supplier, params.projectName, params.partNumber, params.odm]);

  useEffect(() => {
    handleGetEventsPartNumberByDate(dayjs());
  }, [events]);

  return (
    <div>
      {isLoading ? (
        <Loading />
      ) : (
        <S.Container>
          <S.CalendarContainer>
            <S.Title>{t('pages.scheduleQualification.title')}</S.Title>
            <Flex vertical align="flex-start">
              <S.DatePickerButton onClick={handleVisibilitySchedule}>
                <span>{handleGetMonthName('YEAR')}</span>
                <DownOutlined style={{ fontSize: '1.5rem' }} />
              </S.DatePickerButton>
              <S.StyledDatePicker
                value={date}
                picker="year"
                open={visibleYearSchedule}
                onChange={(current) => {
                  if (current && dayjs.isDayjs(current)) {
                    handleUpdateDate(current, 'YEAR');
                    handleVisibilitySchedule();
                  }
                }}
              />
              <Months currentDate={date} onClick={handleUpdateDate} />
            </Flex>
            <Flex vertical gap={29}>
              <S.TitleCurrentMonth>{handleGetMonthName('MONTH')}</S.TitleCurrentMonth>
              <S.StyledCalendar
                fullCellRender={(current, _) => {
                  return handleRenderScheduleDay(current, 'SMALL');
                }}
                headerRender={() => {
                  return null;
                }}
                className="custom-calendar"
                value={date}
                onChange={(current) => {
                  handleUpdateDate(current, 'DAY');
                }}
              />
            </Flex>
          </S.CalendarContainer>
          <S.ContainerRight>
            <S.ContainerFilters>
              <StyledSelect
                label={t('pages.scheduleQualification.labels.partNumber')}
                placeholder={t('pages.scheduleQualification.placeholders.addPartNumber')}
                onChange={(value: string) => {
                  handleUpdateFilters('partNumber', value);
                }}
                options={partNumbers}
                styles={{ minWidth: '9rem' }}
                value={params.partNumber}
                suffixIcon={<SearchOutlined style={{ color: theme.colorsDesignSystem.primary }} />}
              />
              <StyledSelect
                options={projects}
                label={t('pages.scheduleQualification.labels.projectName')}
                placeholder={t('pages.scheduleQualification.placeholders.addName')}
                onChange={(value: string) => {
                  handleUpdateFilters('projectName', value);
                }}
                styles={{ minWidth: '9rem' }}
                value={params.projectName}
                suffixIcon={<SearchOutlined style={{ color: theme.colorsDesignSystem.primary }} />}
              />
              <StyledSelect
                options={suppliers}
                showSearch
                label={t('pages.scheduleQualification.labels.supplier')}
                placeholder={t('pages.scheduleQualification.placeholders.addSupplier')}
                onChange={(value: string) => {
                  handleUpdateFilters('supplier', value);
                }}
                styles={{ minWidth: '9rem' }}
                value={params.supplier}
                suffixIcon={<SearchOutlined style={{ color: theme.colorsDesignSystem.primary }} />}
              />
              <StyledSelect
                options={odms}
                showSearch
                label={t('pages.scheduleQualification.labels.ODM')}
                onChange={(value: string) => {
                  handleUpdateFilters('odm', value);
                }}
                styles={{ minWidth: '6.5rem' }}
                value={params.odm}
              />
              <StyledButton
                small
                onClick={handleFetchScheduleEvents}
                variant="primary"
                text={t('pages.quotes.titleButons.apply')}
              />
            </S.ContainerFilters>
            <S.FiltersTagContainer>
              {handleRenderFiltersTags()}
              {handleRenderClearAllFilters()}
            </S.FiltersTagContainer>

            <S.ContainerSchedule>
              <Calendar
                fullCellRender={(current, _) => {
                  return handleRenderScheduleDay(current, 'NORMAL');
                }}
                headerRender={() => {
                  return null;
                }}
                value={date}
              />
            </S.ContainerSchedule>
          </S.ContainerRight>
          <S.ContainerDetails>
            <Flex vertical>
              <Row justify="space-between" align="middle">
                <S.LabelsRightContent size="large">{date.format('dddd')}</S.LabelsRightContent>
                <S.Badge>{handleGetQuantityEventsToday()}</S.Badge>
              </Row>
              <S.LabelsRightContent size="small">
                {getFormattedDateWithMonthAndDay(date)}
              </S.LabelsRightContent>
            </Flex>
            <S.ContainerCards>
              <Row align="middle" justify="space-between" style={{ marginBottom: '1.5rem' }}>
                <Row align="middle">
                  <S.LabelsRightContent size="medium">
                    {t('pages.scheduleQualification.labels.planDate')}
                  </S.LabelsRightContent>
                  {visibleFirtPlanDate ? (
                    <S.ArrowDown
                      onClick={handleOpenCloseFirstPlanDate}
                      style={{ color: theme.colorsDesignSystem.primary }}
                    />
                  ) : (
                    <S.ArrowUp
                      onClick={handleOpenCloseFirstPlanDate}
                      style={{ color: theme.colorsDesignSystem.primary }}
                    />
                  )}
                </Row>
                <S.Badge
                  plus
                  onClick={() => {
                    setVisibleModalSchedule(true);
                  }}
                >
                  +
                </S.Badge>
              </Row>
              {visibleFirtPlanDate && (
                <S.ContainerSeparateCards>
                  {handleRenderCardsFirsPlanDate()}
                </S.ContainerSeparateCards>
              )}

              <S.RowDelayedCards align="middle">
                <S.LabelsRightContent size="medium">
                  {t('pages.scheduleQualification.labels.delayed')}
                </S.LabelsRightContent>
                {visibleDelayed ? (
                  <S.ArrowDown
                    onClick={handleOpenCloseDelayed}
                    style={{ color: theme.colorsDesignSystem.primary }}
                  />
                ) : (
                  <S.ArrowUp
                    onClick={handleOpenCloseDelayed}
                    style={{ color: theme.colorsDesignSystem.primary }}
                  />
                )}
              </S.RowDelayedCards>
              {visibleDelayed && (
                <S.ContainerSeparateCards>{handleRenderCardsDelayed()}</S.ContainerSeparateCards>
              )}
            </S.ContainerCards>
          </S.ContainerDetails>
        </S.Container>
      )}

      {visibleModalSchedule && (
        <StyledModal
          open={visibleModalSchedule}
          onClose={() => {
            setVisibleModalSchedule(false);
          }}
          body={<ModalNewEvent closeModal={handleCloseModal} />}
          title={t('pages.scheduleQualification.modalNewEvent.title')}
          footer={null}
        />
      )}
    </div>
  );
}
