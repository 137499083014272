import theme from '../../../../../styles/theme';
import { CustomTooltipProps, DataGraphicProps } from '../types';
import * as S from './styles';

export function useGraphic(data: DataGraphicProps) {
  const formatValue = (value: number) => {
    if (value < 10000) {
      return value.toLocaleString('en-US');
    }

    const suffixes = ['', 'K', 'M', 'B', 'T', 'P'];
    const digits = Math.floor(Math.log10(value)) + 1;

    const suffixIndex = Math.floor((digits - 1) / 3);
    let shortValue = value / 1000 ** suffixIndex;
    shortValue = parseFloat(shortValue.toFixed(1));

    return shortValue + suffixes[suffixIndex];
  };

  function highlightColor(valor: number, rgbString?: string) {
    if (rgbString) {
      const matches = rgbString?.match(/rgb\((\d+),\s*(\d+),\s*(\d+)\)/);

      if (!matches) return rgbString;

      let redValue = Number(matches[1]);
      let greenValue = Number(matches[2]);
      let blueValue = Number(matches[3]);

      redValue = Math.min(255, redValue - valor);
      greenValue = Math.min(255, greenValue - valor);
      blueValue = Math.min(255, blueValue - valor);

      return `rgb(${redValue}, ${greenValue}, ${blueValue})`;
    }
  }

  const CustomTooltip = ({ active, payload, label }: CustomTooltipProps) => {
    if (active && payload && payload.length) {
      return (
        <S.TooltipContainer>
          <p>{`${label}`}</p>
          {payload.map((entry, index: number) => {
            const formattedLabel = entry.value.toLocaleString('en-US', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            });
            return (
              // eslint-disable-next-line react/no-array-index-key
              <p key={`item-${index}`} style={{ color: entry.color }}>
                {`$${formattedLabel}`}
              </p>
            );
          })}
        </S.TooltipContainer>
      );
    }
    return null;
  };

  const Customized = (props: any) => {
    const { payload, x, y } = props;

    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={4} textAnchor="end" fill="#666">
          ${formatValue(payload.value)}
        </text>
      </g>
    );
  };

  const verticalCoordinatesGenerator = ({ width, height, xAxis, yAxis }: any) => {
    const numberOfCategories = data.graphic.length;
    let interval = width / numberOfCategories;
    const coordinates = [];

    for (let i = 1; i < numberOfCategories; i++) {
      interval = i === 1 ? interval + 40 : interval - 40;
      coordinates.push(i * interval);
    }

    return coordinates;
  };

  const renderColorfulLegendText = (value: string, entry: any) => {
    return <span style={{ color: theme.colors.inputPlaceHolder }}>{value}</span>;
  };
  return {
    verticalCoordinatesGenerator,
    renderColorfulLegendText,
    CustomTooltip,
    Customized,
    highlightColor,
  };
}
