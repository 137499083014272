import { InfoCircleFilled } from '@ant-design/icons';
import { Tooltip } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Legend,
  ResponsiveContainer,
  Tooltip as RTooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { handleFormatValueFields } from '../../../../../helpers/nUtils';
import theme from '../../../../../styles/theme';
import { GraphicProps } from '../types';
import * as S from './styles';
import { useGraphic } from './useGraphic';

export function Graphic({
  data,
  endColor,
  startColor,
  heightProps = '100%',
  widthProps = '100%',
  partNumber,
}: GraphicProps) {
  const { t } = useTranslation();
  const {
    CustomTooltip,
    Customized,
    highlightColor,
    renderColorfulLegendText,
    verticalCoordinatesGenerator,
  } = useGraphic(data);

  return (
    <S.Graphic key={data.sku} data-testid="graphic-component">
      <S.TableHeader>
        <p data-testid="graphic-title">{data.sku}</p>
        <span data-testid="graphic-category">{data.category}</span>
        {data.spec && <span data-testid="graphic-spec"> | {data.spec}</span>}
        <Tooltip
          arrow={false}
          placement="rightTop"
          title={partNumber?.map((item) => (
            <p key={item}>{item}</p>
          ))}
        >
          <InfoCircleFilled
            style={{
              color: theme.colorsDesignSystem.primary,
              fontSize: 14,
              marginLeft: '0.375rem',
            }}
          />
        </Tooltip>
      </S.TableHeader>
      <ResponsiveContainer width={widthProps} height={heightProps}>
        <BarChart
          role="graphics-symbol"
          barCategoryGap={40}
          barGap={12}
          data={data.graphic}
          margin={{
            right: 20,
            bottom: 16,
          }}
        >
          <CartesianGrid
            strokeDasharray="3 3"
            verticalCoordinatesGenerator={verticalCoordinatesGenerator}
          />
          <XAxis dataKey="name" />
          <YAxis
            tickLine={false}
            tick={<Customized />}
            axisLine={false}
            type="number"
            domain={([dataMin, dataMax]) => {
              const valorForMultiple = dataMax < 1000 ? 1000 : 1500;
              const min = Math.floor(dataMin / 1000) * 1000;
              let max = Math.ceil(dataMax / 1000) * valorForMultiple;
              max = max === dataMax ? max + 1000 : max;
              return [min, max];
            }}
          />
          <RTooltip content={<CustomTooltip />} />
          <Legend align="right" iconType="circle" formatter={renderColorfulLegendText} />
          <Bar
            dataKey="start"
            fill={startColor}
            name={t('pages.finance.dashboard.startvsend.legendStart')}
            barSize={26}
            label={{
              position: 'top',
              fill: theme.colors.inputPlaceHolder,
              formatter: (value: number) =>
                `${handleFormatValueFields({
                  number: value,
                  notation: 'compact',
                  minFractional: 0,
                  maxFactional: 2,
                })}`,
            }}
            radius={[4, 4, 0, 0]}
          >
            {data.graphic.map((entry) => {
              return (
                <Cell
                  key={entry.name}
                  fill={
                    entry.name === dayjs().format('MMM/YYYY').toUpperCase()
                      ? highlightColor(60, startColor)
                      : startColor
                  }
                />
              );
            })}
          </Bar>
          <Bar
            dataKey="end"
            fill={endColor}
            barSize={26}
            name={t('pages.finance.dashboard.startvsend.legendEnd')}
            label={{
              position: 'top',
              fill: theme.colors.inputPlaceHolder,
              formatter: (value: number) =>
                `${handleFormatValueFields({
                  number: value,
                  notation: 'compact',
                  minFractional: 0,
                  maxFactional: 2,
                })}`,
            }}
            radius={[4, 4, 0, 0]}
          />
        </BarChart>
      </ResponsiveContainer>
    </S.Graphic>
  );
}
