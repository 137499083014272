import { ExclamationCircleFilled, InfoCircleOutlined } from '@ant-design/icons';
import { Col, DatePicker, Flex, Form, Input, Row } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { ContainerComponents, FormContent, FormFooter, LastPriceContent } from './styles';
import { IStep2, ITransferPartNumber, ITransferProjet } from './types';
import { useRegisterComponents } from './useRegisterComponents';

import StyledButton from '../../../../components/Common/StyledButton';
import { StyledLoading } from '../../../../components/Common/StyledLoading';
import StyledMultipleSelect from '../../../../components/Common/StyledMultipleSelect';
import StyledSelect from '../../../../components/Common/StyledSelect';
import { TableTransfer } from '../../../../components/Common/StylesTableTransfer';
import { handleFormatValueFields, removeDiplicated } from '../../../../helpers/nUtils';
import { DeleteCategoryModal } from '../../DeleteCategoryModal';
import { CompressedComponent } from '../components/CompressedComponents';
import * as S from '../styles';

const { RangePicker } = DatePicker;

export function RegisterComponents({
  handleSubmit,
  nextStep,
  previousStep,
  onCancel,
  bidId,
  originalBidId,
}: IStep2) {
  const { t } = useTranslation();
  const {
    categories,
    projects,
    specs,
    targetProjects,
    partNumbers,
    targetPartNumbers,
    partNumbersColumns,
    projectsColumns,
    componentIdOpen,
    isNewComponentOpen,
    selectedProjects,
    selectedPartNumbers,
    selectedSpecs,
    openDeleteModal,
    components,
    form,
    isLoading,
    isLoadingSubmit,
    isLoadingData,
    onChangeProject,
    onChangePartNumber,
    setComponentIdOpen,
    setIsNewComponentOpen,
    setSelectedCategory,
    setSelectedSpecs,
    onEditComponent,
    setOpenDeleteModal,
    setSelectedPartNumbers,
    setSelectedProjects,
    setTargetPartNumbers,
    setTargetProjects,
    deleteComponent,
    fetchComponents,
    setSelectedDemandPeriod,
    selectedDemandPeriod,
    fromToOptions,
  } = useRegisterComponents(bidId);

  const filterPartNumbers = (inputValue: string, item: ITransferPartNumber) => {
    return (
      item.partNumber.toLocaleLowerCase().indexOf(inputValue.toLocaleLowerCase()) !== -1 ||
      item.qstatus.toLocaleLowerCase().indexOf(inputValue.toLocaleLowerCase()) !== -1 ||
      item.supplier.toLocaleLowerCase().indexOf(inputValue.toLocaleLowerCase()) !== -1 ||
      item.project.toLocaleLowerCase().indexOf(inputValue.toLocaleLowerCase()) !== -1
    );
  };

  const validatePrices = (value: string) => {
    if (!value || /^\d+\.\d{2}$/.test(value)) {
      return true;
    }
    return false;
  };

  const validateValue = (value: string) => {
    if (!value || /^\d+(\.\d+)?$/.test(value)) {
      return true;
    }
    return false;
  };

  const validatePeriod = () => {
    if (fromToOptions.length > 0) return true;
    return false;
  };

  const renderForm = () => {
    return (
      <S.StyledForm removePadding removeBorder>
        <Form
          layout="vertical"
          requiredMark
          form={form}
          onFinish={() => {
            form.validateFields().then(async () => {
              handleSubmit({
                ...form.getFieldsValue(true),
                projects: selectedProjects,
                partNumbers: selectedPartNumbers,
              }).then(() => {
                isNewComponentOpen ? setIsNewComponentOpen(false) : setComponentIdOpen(null);
                form.resetFields();
                fetchComponents();
              });
            });
          }}
        >
          <FormContent>
            {/* em caso de novo round, mostra menor preço de round anterior */}
            {originalBidId && (
              <LastPriceContent>
                <ExclamationCircleFilled />
                {t('pages.newRfq.steps.registerComponents.fields.lowestPreviousPrice', {
                  value: handleFormatValueFields({
                    number: form.getFieldValue('lowestPreviousPrice'),
                  }),
                })}
              </LastPriceContent>
            )}
            <Form.Item noStyle>
              <Row gutter={24}>
                <Col span={8}>
                  <Form.Item
                    data-cy="registerComponent-altGroup"
                    label={t('pages.newRfq.steps.registerComponents.fields.altGroup')}
                    name="altGroup"
                    rules={[
                      {
                        required: true,
                        message: `${t('pages.newRfq.steps.registerComponents.fields.altGroup')} ${t(
                          `common.validate.required`
                        )}`,
                      },
                      {
                        min: 2,
                        message: t(
                          'pages.newRfq.steps.registerComponents.fields.altGroupMinCharacter'
                        ),
                      },
                      {
                        max: 25,
                        message: t(
                          'pages.newRfq.steps.registerComponents.fields.altGroupMaxCharacter'
                        ),
                      },
                    ]}
                  >
                    <Input
                      placeholder={t(
                        'pages.newRfq.steps.registerComponents.fields.placeholderAltGroup'
                      )}
                      minLength={2}
                      maxLength={70}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    data-cy="registerComponent-category"
                    label={t('pages.newRfq.steps.registerComponents.fields.category')}
                    name="category"
                    rules={[
                      {
                        required: true,
                        message: `${t('pages.newRfq.steps.registerComponents.fields.category')} ${t(
                          `common.validate.required`
                        )}`,
                      },
                    ]}
                  >
                    <StyledSelect
                      options={categories}
                      showSearch
                      placeholder={t(
                        'pages.newRfq.steps.registerComponents.fields.placeholderCategory'
                      )}
                      onChange={(e: string) => {
                        setSelectedCategory(e);
                        setSelectedSpecs(null);
                        form.setFieldValue('specifications', []);
                        setTargetProjects([]);
                        setSelectedProjects([]);
                        setSelectedPartNumbers([]);
                        setTargetPartNumbers([]);
                      }}
                      loading={isLoadingData.loadingCategories}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item noStyle shouldUpdate>
                    {() => {
                      return (
                        <Form.Item
                          data-cy="registerComponent-specifications"
                          label={t('pages.newRfq.steps.registerComponents.fields.specification')}
                          name="specifications"
                          rules={[
                            {
                              required: true,
                              message: `${t(
                                'pages.newRfq.steps.registerComponents.fields.specification'
                              )} ${t(`common.validate.required`)}`,
                            },
                          ]}
                        >
                          <StyledMultipleSelect
                            value={selectedSpecs}
                            options={specs}
                            showSearch
                            placeholder={t(
                              'pages.newRfq.steps.registerComponents.fields.placeholderSpecification'
                            )}
                            onChange={(e: string) => {
                              setSelectedSpecs([e]);
                            }}
                            loading={isLoadingData.loadingSpecs}
                            allowClear
                          />
                        </Form.Item>
                      );
                    }}
                  </Form.Item>
                </Col>
              </Row>
            </Form.Item>
            <Form.Item
              data-cy="registerComponent-projects"
              label={t('pages.newRfq.steps.registerComponents.fields.projects')}
              name="projects"
              rules={[
                {
                  required: true,
                  message: `${t('pages.newRfq.steps.registerComponents.fields.projects')} ${t(
                    `common.validate.required`
                  )}`,
                },
              ]}
            >
              <TableTransfer<ITransferProjet>
                dataSource={projects}
                targetKeys={targetProjects}
                showSelectAll={false}
                onChange={onChangeProject}
                leftColumns={projectsColumns}
                loading={isLoadingData.loadingProjects}
                showSearch
                filterOption={(inputValue, item) =>
                  item.project.toLocaleLowerCase().indexOf(inputValue.toLocaleLowerCase()) !== -1 ||
                  item.cm.toLocaleLowerCase().indexOf(inputValue.toLocaleLowerCase()) !== -1
                }
                rightColumns={projectsColumns}
                titles={[
                  t('pages.newRfq.steps.registerComponents.fields.all'),
                  t('pages.newRfq.steps.registerComponents.fields.selected'),
                ]}
              />
            </Form.Item>
            <Form.Item
              data-cy="registerComponent-partNumbers"
              label={t('pages.newRfq.steps.registerComponents.fields.partNumbers')}
              name="partNumbers"
              rules={[
                {
                  required: true,
                  message: `${t('pages.newRfq.steps.registerComponents.fields.partNumbers')} ${t(
                    `common.validate.required`
                  )}`,
                },
              ]}
            >
              <TableTransfer<ITransferPartNumber>
                dataSource={partNumbers}
                targetKeys={targetPartNumbers}
                showSelectAll={false}
                onChange={onChangePartNumber}
                leftColumns={partNumbersColumns}
                loading={isLoadingData.loadingPartNumber}
                rightColumns={partNumbersColumns}
                showSearch
                filterOption={filterPartNumbers}
                titles={[
                  t('pages.newRfq.steps.registerComponents.fields.all'),
                  t('pages.newRfq.steps.registerComponents.fields.selected'),
                ]}
              />
            </Form.Item>
            <Form.Item noStyle>
              <Row gutter={24}>
                <Col>
                  <Form.Item
                    data-cy="registerComponent-demandPeriod"
                    label={t('pages.newRfq.steps.registerComponents.fields.demandPeriod')}
                    name="demandPeriod"
                    tooltip={{
                      title: t('pages.newRfq.steps.registerComponents.fields.demandPeriodTooltip'),
                      icon: <InfoCircleOutlined />,
                    }}
                    rules={[
                      {
                        required: true,
                        message: `${t(
                          'pages.newRfq.steps.registerComponents.fields.demandPeriod'
                        )} ${t(`common.validate.required`)}`,
                      },
                      {
                        validator: () =>
                          validatePeriod()
                            ? Promise.resolve()
                            : Promise.reject(
                                t('pages.newRfq.steps.registerComponents.fields.demandPeriodError')
                              ),
                      },
                    ]}
                  >
                    <RangePicker
                      placeholder={[
                        t('pages.newRfq.steps.registerComponents.fields.startDatePlaceholder'),
                        t('pages.newRfq.steps.registerComponents.fields.endDatePlaceholder'),
                      ]}
                      onChange={(e) => {
                        if (e === null) {
                          form.setFieldValue('fromTo', null);
                        }
                        setSelectedDemandPeriod(e);
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item
                    data-cy="registerComponent-from"
                    label={t('pages.newRfq.steps.registerComponents.fields.fromTo')}
                    name="fromTo"
                    tooltip={{
                      title: t('pages.newRfq.steps.registerComponents.fields.fromToTooltip'),
                      icon: <InfoCircleOutlined />,
                    }}
                  >
                    <StyledSelect
                      options={fromToOptions}
                      placeholder={t('pages.newRfq.steps.registerComponents.fields.fromTo')}
                      onChange={() => null}
                      showSearch
                      loading={isLoadingData.loadingFromTo}
                      disable={
                        fromToOptions?.length === 0 ||
                        isLoadingData.loadingFromTo ||
                        selectedDemandPeriod === null
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form.Item>
            <Form.Item noStyle>
              <Flex gap={24}>
                <Form.Item
                  data-cy="registerComponent-startingPrice"
                  label={t('pages.newRfq.steps.registerComponents.fields.startingPrice')}
                  name="startingPrice"
                  rules={[
                    { required: false },
                    {
                      validator: (_, value) =>
                        validatePrices(value)
                          ? Promise.resolve()
                          : Promise.reject(
                              t('pages.newRfq.steps.registerComponents.fields.priceError')
                            ),
                    },
                    {
                      validator: (_, value) =>
                        validateValue(value)
                          ? Promise.resolve()
                          : Promise.reject(t('toast.invalidField')),
                    },
                  ]}
                  style={{ width: '12.5rem' }}
                >
                  <Input
                    placeholder={t(
                      'pages.newRfq.steps.registerComponents.fields.placeholderStartingPrice'
                    )}
                    minLength={2}
                    maxLength={70}
                  />
                </Form.Item>
                <Form.Item
                  data-cy="registerComponent-fobPrice"
                  label={t('pages.newRfq.steps.registerComponents.fields.fobPrice')}
                  name="fobPrice"
                  rules={[
                    { required: false },
                    {
                      validator: (_, value) =>
                        validatePrices(value)
                          ? Promise.resolve()
                          : Promise.reject(
                              t('pages.newRfq.steps.registerComponents.fields.priceError')
                            ),
                    },
                    {
                      validator: (_, value) =>
                        validateValue(value)
                          ? Promise.resolve()
                          : Promise.reject(t('toast.invalidField')),
                    },
                  ]}
                  tooltip={{
                    title: t('pages.newRfq.steps.registerComponents.fields.fobPriceTooltip'),
                    icon: <InfoCircleOutlined />,
                  }}
                  style={{ width: '12.5rem' }}
                >
                  <Input
                    placeholder={t(
                      'pages.newRfq.steps.registerComponents.fields.placeholderStartingPrice'
                    )}
                    minLength={2}
                    maxLength={6}
                  />
                </Form.Item>
                <Form.Item
                  data-cy="registerComponent-lastFobPrice"
                  label={t('pages.newRfq.steps.registerComponents.fields.lastFOBPrice')}
                  name="lastFobPrice"
                  rules={[
                    { required: false },
                    {
                      validator: (_, value) =>
                        validatePrices(value)
                          ? Promise.resolve()
                          : Promise.reject(
                              t('pages.newRfq.steps.registerComponents.fields.priceError')
                            ),
                    },
                    {
                      validator: (_, value) =>
                        validateValue(value)
                          ? Promise.resolve()
                          : Promise.reject(t('toast.invalidField')),
                    },
                  ]}
                  tooltip={{
                    title: t('pages.newRfq.steps.registerComponents.fields.lastFOBPriceTooltip'),
                    icon: <InfoCircleOutlined />,
                  }}
                  style={{ width: '12.5rem' }}
                >
                  <Input
                    placeholder={t(
                      'pages.newRfq.steps.registerComponents.fields.placeholderStartingPrice'
                    )}
                    minLength={2}
                    maxLength={6}
                  />
                </Form.Item>
                <Form.Item
                  data-cy="registerComponent-baselinePrice"
                  label={t('pages.newRfq.steps.registerComponents.fields.baselinePrice')}
                  name="baselinePrice"
                  rules={[
                    {
                      required: true,
                      message: `${t(
                        'pages.newRfq.steps.registerComponents.fields.baselinePrice'
                      )} ${t(`common.validate.required`)}`,
                    },
                    {
                      validator: (_, value) =>
                        validatePrices(value)
                          ? Promise.resolve()
                          : Promise.reject(
                              t('pages.newRfq.steps.registerComponents.fields.priceError')
                            ),
                    },
                    {
                      validator: (_, value) =>
                        validateValue(value)
                          ? Promise.resolve()
                          : Promise.reject(t('toast.invalidField')),
                    },
                  ]}
                  tooltip={{
                    title: t('pages.newRfq.steps.registerComponents.fields.baselinePriceTooltip'),
                    icon: <InfoCircleOutlined />,
                  }}
                  style={{ width: '12.5rem' }}
                >
                  <Input
                    placeholder={t(
                      'pages.newRfq.steps.registerComponents.fields.placeholderStartingPrice'
                    )}
                    minLength={2}
                    maxLength={6}
                  />
                </Form.Item>
              </Flex>
            </Form.Item>
          </FormContent>
          <FormFooter>
            <Form.Item shouldUpdate>
              {() => {
                return (
                  <StyledButton
                    variant="primary"
                    loading={isLoadingSubmit}
                    data-cy="registerComponent-saveComponent"
                    disabled={
                      isLoadingData.loadingPartNumber ||
                      isLoadingData.loadingProjects ||
                      isLoadingData.loadingFromTo
                    }
                    htmlType="submit"
                  >
                    {t('common.save')}
                  </StyledButton>
                );
              }}
            </Form.Item>
          </FormFooter>
        </Form>
      </S.StyledForm>
    );
  };

  return (
    <>
      <DeleteCategoryModal
        isOpen={openDeleteModal.isModalOpen}
        onDelete={() => {
          if (openDeleteModal.componentId) {
            deleteComponent(openDeleteModal.componentId);
          } else {
            form.resetFields();
            setComponentIdOpen(null);
            setIsNewComponentOpen(false);
          }
          setOpenDeleteModal({
            isModalOpen: false,
            // eslint-disable-next-line no-undefined
            componentId: undefined,
          });
          setComponentIdOpen(null);
        }}
        onCloseModal={() =>
          setOpenDeleteModal({
            isModalOpen: false,
            // eslint-disable-next-line no-undefined
            componentId: undefined,
          })
        }
        loading={isLoading}
      />
      {isLoadingData.loadingComponents ? (
        <StyledLoading height={50} />
      ) : (
        <ContainerComponents>
          <S.ContentForm>
            {components.map((component) => {
              return (
                <CompressedComponent
                  step="2"
                  key={component.stepComponentId}
                  index={component.stepComponentId}
                  title={`${component.category ?? ''} | ${component.altGroup}`}
                  isOpen={
                    (componentIdOpen && componentIdOpen === component.stepComponentId) || false
                  }
                  onDelete={() => {
                    setOpenDeleteModal({
                      isModalOpen: true,
                      componentId: component.stepComponentId,
                    });
                  }}
                  disableDelete={isLoadingSubmit}
                  disableEdit={isLoadingSubmit}
                  onClose={() => {
                    form.validateFields().then(() => {
                      form.resetFields();
                      setComponentIdOpen(null);
                    });
                  }}
                  onEdit={() => {
                    onEditComponent(component);
                    form.setFieldsValue({
                      ...component,
                      fromTo: `${component.from}/${component.to}`,
                      demandPeriod: [
                        dayjs(component.demandPeriod[0]),
                        dayjs(component.demandPeriod[1]),
                      ],
                    });
                    component.stepComponentId && setComponentIdOpen(component.stepComponentId);
                    setIsNewComponentOpen(false);
                  }}
                  demandPeriod={
                    component && component.demandPeriod
                      ? `${dayjs(component?.demandPeriod[0])?.format('YYYY/MM/DD')}  - ${dayjs(
                          component?.demandPeriod[1]
                        )?.format('YYYY/MM/DD')}`
                      : '-'
                  }
                  staringPrice={component.startingPrice}
                  suppliers={removeDiplicated(
                    component.partNumbers.map((partNumber) => partNumber.supplier)
                  )}
                >
                  {renderForm()}
                </CompressedComponent>
              );
            })}
            {isNewComponentOpen && (
              <CompressedComponent
                step="2"
                isOpen={isNewComponentOpen}
                onDelete={() => {
                  if (Object.keys(form.getFieldsValue(true)).length > 0) {
                    setOpenDeleteModal({ isModalOpen: true });
                  } else {
                    form.resetFields();
                    setComponentIdOpen(null);
                    setIsNewComponentOpen(false);
                  }
                }}
                disableDelete={isLoadingSubmit}
                disableEdit={isLoadingSubmit}
                onClose={() => {
                  if (Object.keys(form.getFieldsValue(true)).length > 0) {
                    form.validateFields().then(() => {
                      handleSubmit({
                        ...form.getFieldsValue(true),
                        sketch: true,
                        projects: selectedProjects,
                        partNumbers: selectedPartNumbers,
                      }).then(() => {
                        form.resetFields();
                        setIsNewComponentOpen(false);
                        fetchComponents();
                      });
                    });
                  } else setIsNewComponentOpen(false);
                }}
                onEdit={() => {
                  setIsNewComponentOpen(false);
                }}
              >
                {renderForm()}
              </CompressedComponent>
            )}
            <StyledButton
              variant="dashed"
              style={{ width: '100%' }}
              disabled={!!componentIdOpen || isNewComponentOpen || isLoadingSubmit}
              onClick={() => setIsNewComponentOpen(true)}
              data-cy="addComponent-step2"
            >
              + {t('pages.newRfq.steps.registerComponents.addComponent')}
            </StyledButton>
          </S.ContentForm>
        </ContainerComponents>
      )}
      <S.StepFooter>
        <StyledButton variant="slim" onClick={onCancel}>
          {t('common.cancel')}
        </StyledButton>

        <Row style={{ gap: '1rem' }}>
          <StyledButton
            variant="secondary"
            onClick={() => {
              if (Object.keys(form.getFieldsValue(true)).length > 0) {
                form.validateFields().then(() => {
                  handleSubmit(form.getFieldsValue(true)).then(() => {
                    previousStep();
                  });
                });
              } else previousStep();
            }}
            disabled={
              isLoadingData.loadingPartNumber ||
              isLoadingData.loadingProjects ||
              isLoadingData.loadingComponents
            }
            data-cy="registerComponent-previousStep"
          >
            {t('common.previous')}
          </StyledButton>
          <StyledButton
            variant="primary"
            onClick={() => {
              form.validateFields().then(() => {
                handleSubmit({
                  ...form.getFieldsValue(true),
                  projects: selectedProjects,
                  partNumbers: selectedPartNumbers,
                }).then(() => {
                  nextStep();
                });
              });
            }}
            disabled={
              components.length === 0 ||
              isLoadingData.loadingPartNumber ||
              isLoadingData.loadingProjects ||
              isLoadingData.loadingComponents
            }
            htmlType="submit"
            data-cy="registerComponent-next"
          >
            {t('common.next')}
          </StyledButton>
        </Row>
      </S.StepFooter>
    </>
  );
}
