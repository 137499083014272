import { Flex } from 'antd';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  border-radius: 0.75rem;
  background-color: ${({ theme }) => theme.colorsDesignSystem.white};
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
`;

export const Padding = styled(Flex)`
  padding: 1.625rem 2rem;
  align-items: center;
  gap: 0.75rem;
`;

export const Header = styled(Flex)`
  font-size: 1.25rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colorsDesignSystem.text};
  border-bottom: 1px solid ${({ theme }) => theme.colorsDesignSystem.tertiaryBorder};

  svg {
    font-size: 1.5rem;
    color: ${({ theme }) => theme.colorsDesignSystem.iconGrey};
  }
`;

export const Content = styled(Flex)`
  padding: 2rem;
  flex-direction: column;
`;

export const FileName = styled(Flex)`
  align-items: center;
  color: ${({ theme }) => theme.colorsDesignSystem.primary};
  font-size: 0.875rem;
  font-weight: 700;
  gap: 0.5rem;
  cursor: pointer;
`;
